/**
 * Scroll to a specified selector
 * @param id
 * @param offset
 * @param callback
 */
export function scrollToSelector(id, offset = 0, callback = '') {
    const element = document.querySelector(id);
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - (offset * -1);
    const offsetPositionFixed = offsetPosition.toFixed();

    const callScrollToSelectorCallback = () => {
        if (window.pageYOffset.toFixed() === offsetPositionFixed) {
            window.removeEventListener('scroll', callScrollToSelectorCallback);
            callback();
        }
    };

    if (typeof callback === 'function') {
        window.addEventListener('scroll', callScrollToSelectorCallback);
        callScrollToSelectorCallback();
    }

    window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
    });
}
